import React from 'react'
import { Link, graphql } from 'gatsby'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import { Disqus, CommentCount } from 'gatsby-plugin-disqus'
import Icon from '@mdi/react'
import { mdiComment, mdiClockOutline } from '@mdi/js'

import Bio from '../components/bio'
import Layout from '../components/layout'
import SEO from '../components/seo'
import { rhythm, scale } from '../utils/typography'

class BlogPostTemplate extends React.Component {
  render () {
    const post = this.props.data.mdx
    const siteTitle = this.props.data.site.siteMetadata.title
    const { previous, next } = this.props.pageContext

    let disqusConfig = {
      url: `${this.props.data.site.siteMetadata.siteUrl +
        this.props.location.pathname}`,
      identifier: post.id,
      title: post.frontmatter.title
    }

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO
          title={post.frontmatter.title}
          description={post.frontmatter.description || post.excerpt}
        />
        <h1>{post.frontmatter.title}</h1>
        <p
          style={{
            ...scale(-1 / 5),
            display: 'flex',
            alignContent: 'center',
            marginBottom: rhythm(1),
            marginTop: rhythm(-1)
          }}
        >
          <span
            style={{
              display: 'flex',
              alignItems: 'center',
              marginRight: rhythm(0.1)
            }}
          >
            <Icon
              path={mdiClockOutline}
              title='Data de Publicação'
              size={0.7}
              horizontal
            />
          </span>
          {post.frontmatter.date}

          <span
            style={{
              display: 'flex',
              alignItems: 'center',
              marginLeft: rhythm(0.5),
              marginRight: rhythm(0.1)
            }}
          >
            <Icon path={mdiComment} title='Commentário' size={0.7} horizontal />
          </span>
          <CommentCount config={disqusConfig} placeholder={'...'} />
        </p>

        <MDXRenderer>{post.body}</MDXRenderer>

        <hr
          style={{
            marginBottom: rhythm(0.7)
          }}
        />

        <Bio />

        <hr
          style={{
            marginTop: rhythm(0.4)
          }}
        />

        {(previous || next) && (
          <div>
            <ul
              style={{
                display: `flex`,
                flexWrap: `wrap`,
                justifyContent: (previous && next) ? 'space-between' : (previous) ? 'flex-start' : 'flex-end',
                listStyle: `none`,
                padding: 0,
                margin: 0
              }}
            >
              {previous && (
                <li style={{
                  padding: 0,
                  margin: 0
                }}>
                  <Link to={previous.fields.slug.startsWith("/blog") ? previous.fields.slug : `/blog${previous.fields.slug}`} rel='prev'>
                    ← {previous.frontmatter.title}
                  </Link>
                </li>
              )}
              {next && (
                <li style={{
                  padding: 0,
                  margin: 0
                }}>
                  <Link to={next.fields.slug.startsWith("/blog") ? next.fields.slug : `/blog${next.fields.slug}`} rel='next'>
                    {next.frontmatter.title} →
                  </Link>
                </li>
              )}
            </ul>

            <hr
              style={{
                marginTop: rhythm(1),
                marginBottom: rhythm(1)
              }}
            />
          </div>
        )}

        <Disqus config={disqusConfig} />
      </Layout>
    )
  }
}

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        author
        siteUrl
      }
    }
    mdx(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      body
      frontmatter {
        title
        date(formatString: "DD MMMM, YYYY", locale: "pt")
        description
      }
    }
  }
`

export default BlogPostTemplate
